import React from "react";
import { useState, useEffect } from "react";
import ForeignLanguageCard from "./ForeignLanguageCard";
import SkillSection from "./SkillSection";

export default function Skills({ sectionName, Skills }) {
  //   const menuItems = ["Home", "About Me", "Resume", "Contact Me"];

  const [navbarOpen, setNavbarOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("Home");

  const title = "🌐 Natural Languages";

  const languages = [
    {name: "china",
     read: 100,
     write: 100,
     speak: 100
    },
    {name: "japan",
     read: 90,
     write: 90,
     speak: 80
    },
    {name: "usa",
     read: 80,
     write: 80,
     speak: 70
    },
  ]

  return (

    <section className="bg-white-100 text-gray-800">
    <div className="container p-6 mx-auto space-y-6 text-center lg:p-8 lg:space-y-8">
      <h2 className="text-5xl font-bold">{title}</h2>
      <div className="flex flex-wrap justify-center gap-6 md:gap-20">
          {languages &&
              languages.map((lan, index) => {
              return (
                <ForeignLanguageCard name={lan.name} read={lan.read} write={lan.write} speak={lan.speak}></ForeignLanguageCard>
              );
          })}        
      </div>
    </div>
    </section>
  );
}
