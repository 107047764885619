import React from "react";
import { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import programPng from "../programer.png";
import ServiceCard from "./ServiceCard";
import lantingjixu from "../img/lantingjixu.jpg"
import jiangshan from "../img/jiangshan.jpg"
import redfuji from "../img/redfuji.jpg"
import starnight from "../img/starnight.jpg"
import hanshitie from '../img/hanshitie.jpg'

export default function Services({ tips, children }) {
  const menuItems = ["Home", "About Me", "Resume", "Contact Me"];

  const [navbarOpen, setNavbarOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("Home");

  const services = [
    {
      title: "System architecture",
      subTitle:
        "Due to customer's business needs, trade off five aspects in cost, performance, security, Reliability and maintenance, make the most suitable choice for you.",
      introduces: [
        {
          title: "Architecture design book proposal.",
          subTitle:
            "Not only software, but also infrastructure.Not only FrontEnd, but also BackEnd. Bring you business near technology, thus speed up your business.",
        },
        {
          title: "Land proposal onto technology stacks.",
          subTitle:
            "Build technology base with suitable technology stacks with sources, help your idea take off.",
        },
        {
          title: "Existing architecture review.",
          subTitle:
            "Find the weakness or problem in existing architecture, help your system run well and healthily.",
        },
      ],
      
      featureImage: hanshitie
    },
    {
      title: "Full Stack Development",
      subTitle:
        "Not only the backend, but also the frontend, of course including DB design.I provide one stop software development.",
      introduces: [
        {
          title: "BackEnd development",
          subTitle:
            "Build system using Java, python, javascript, or php, or other else.",
        },
        {
          title: "FrontEnd development",
          subTitle:
            "Build technology base with suitable technology stacks with sources, help your idea take off.",
        },
        {
          title: "DB design and Data design",
          subTitle:
            "ER design, Table design, and data design. ",
        },
      ],
      featureImage: jiangshan
    },
    {
      title: "Project management",
      subTitle:
        "Lead and push team members towards predefined goal, within the constraint of Time, Cost and Quantity. ",
      introduces: [
        {
          title: "Build project plan",
          subTitle:
            "Build reasonable project plan due to deep understanding of technology issues. ",
        },
        {
          title: "Monitor project status",
          subTitle:
            "Find out the gap between plan and fact , keep the project run along right way. ",
        },
        {
          title: "Solve project problem ",
          subTitle:
            "Pick out the problems, solve them with priority.",
        },
      ],
      featureImage: redfuji
    },
    {
      title: "Training & Education",
      subTitle:
        "",
      introduces: [
        {
          title: "Traditional training",
          subTitle:
            "Make training plan, provide multi stack technology training on demand.",
        },
        {
          title: "OJT",
          subTitle:
            "Share my experience to help fellow members grow up quickly.",
        },
        {
          title: "Online Training",
          subTitle:
            "Using zoom or other tools to provide online training, beyond the constraint of distance.",
        },
      ],
      featureImage: starnight
    },
  ];

  return (
    <section className="bg-gray-100 text-gray-800" id="Services">
      <div className="container max-w-xl p-6 py-6 mx-auto space-y-8 lg:px-8 lg:max-w-7xl">
        <div>
          <h2 className="text-5xl font-bold tracking-tight text-center sm:text-5xl text-gray-900">
            🛎️ Services
          </h2>
          <p className="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-600">
               What I am professional at.
          </p>
        </div>

        {services.map((service, index) => {
          return <ServiceCard service={service} index={index} />;
        })}
      </div>
    </section>
  );
}
