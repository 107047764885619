import logo from './logo.svg';
import './App.css';
import Navbar from './component/Navbar'
import Hero from './component/Hero'
import Skills from './component/Skills'
import Projects from './component/Projects';
import Footer from './component/Footer';

import Services from './component/Services'
import About from './component/About'

function App() {
  return (
    <>
      <Navbar className="print:hidden" />
      <Hero className="print:hidden" />
      <About />
      <Services></Services>
      
      <Skills />
      <Projects />
      <Footer />
    </>
  );
}

export default App;
