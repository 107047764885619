import React from "react";
import { useState, useEffect } from "react";
import SkillSection from "./SkillSection";
import ForeignLanguages from './ForeignLanguages'
import go from "../img/go.svg";
import mysql from "../img/mysql.svg";
import postgresql from "../img/postgresql.svg";
import mybatis from "../img/mybatis.svg";
import keycloak from "../img/keycloak.svg";
import FirebaesSVG from "../img/firebase.svg";
import Typescript from "../img/Typescript.svg";
import PHP from "../img/PHP.svg";
import es from "../img/es.svg";
import mongo from "../img/mongo.svg";
import azure from "../img/azure.svg";
import k8s from "../img/k8s.svg";
import redis from "../img/redis.svg";
import vscode from "../img/vscode.svg";
import idea from "../img/idea.svg";
import tailwind from "../img/tailwind.svg";
import mui from "../img/mui.png";
import bootstrap from "../img/bootstrap.svg";
import jquery from "../img/jquery.svg";
import expressjs from "../img/expressjs.svg";
import antdesign from "../img/antdesign.svg";
import nodejs from "../img/nodejs.svg";
import kafka from "../img/kafka.svg";
import googlecloud from "../img/googlecloud.png";
import tomcat from "../img/tomcat.png";
import apache from "../img/apache.png";
import git from "../img/git.svg";
import jenkins from "../img/jenkins.svg";
import more from "../img/more.svg";
import django from "../img/django.svg";
import maven from "../img/maven.svg";


export default function Skills({ sectionName, Skills }) {
  //   const menuItems = ["Home", "About Me", "Resume", "Contact Me"];

  const [navbarOpen, setNavbarOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("Home");

  return (
    <div className="md:w-8/12 w-10/12 mx-auto" id="Skills">
      <ForeignLanguages />
      <div className="w-full h-full">
        <SkillSection
          title="⌨️ Program Language"
          skills={[
            // 在此处新增或删除技能项，每个技能项用逗号进行分隔
            // 图标来源：https://fontawesome.com/icons
            { name: "Python", icon: "fab fa-python fa-3x", experience: 90 },            
            { name: "Java", icon: "fab fa-java fa-3x", experience: 90 },                       
            { name: "PHP", icon: "fa fa-php fa-3x", svg: PHP, experience: 80 },
            { name: "GO", icon: "fab fa-golang fa-3x", svg: go, experience: 50 },
            { name: "C", icon: "fas fa-copyright fa-3x", experience: 50 },             
            {
              name: "Javascript",
              icon: "fab fa-js-square fa-3x",
              experience: 80,
            },
            {
              name: "Typescript",
              icon: "fab fa-js-square fa-3x",
              svg: Typescript,
              experience: 80,
            },
            { name: "HTML", icon: "fab fa-html5 fa-3x", experience: 80 },
            { name: "CSS", icon: "fab fa-css3-alt fa-3x", experience: 70 },            
            { name: "SQL", icon: "fas fa-database fa-3x", experience: 90 },  
            { name: "More", icon: "fas fa-copyright fa-3x", svg: more, experience: 100  },
          ]}
        />
      </div>
      <div className="w-full h-full">
        <SkillSection
          title="📚 Library/FrameWork"
          skills={[
            // 在此处新增或删除技能项，每个技能项用逗号进行分隔
            // 图标来源：https://fontawesome.com/icons
            { name: "React", icon: "fab fa-react fa-3x", experience: 80  },
            { name: "Vue", icon: "fab fa-vuejs fa-3x", experience: 70  },
            { name: "Tailwind", icon: "fas fa-table fa-3x", svg: tailwind, experience: 90  },
            { name: "MUI", icon: "fas fa-table fa-3x", svg: mui, experience: 90  },
            { name: "Ant Design", icon: "fas fa-table fa-3x", svg: antdesign, experience: 90  },
            { name: "Bootstrap", icon: "fas fa-table fa-3x", svg: bootstrap, experience: 90  },
            { name: "jQuery", icon: "fas fa-copyright fa-3x", svg: jquery, experience: 90  },            
            { name: "ExpressJS", icon: "fas fa-server fa-3x", svg: expressjs, experience: 80  },
            { name: "Spring", icon: "fa fa-leaf fa-3x", experience: 90  },
            { name: "Mybatis", icon: "fas fa-copyright fa-3x", svg: mybatis, experience: 90  },
            { name: "Flask", icon: "fas fa-pepper-hot fa-3x", experience: 90  },
            { name: "Django", icon: "fas fa-pepper-hot fa-3x", svg:django, experience: 80  },
            { name: "Firebase", icon: "fas fa-table fa-3x", svg:FirebaesSVG,  experience: 80  },                        
            { name: "Keycloak", icon: "fas fa-copyright fa-3x", svg: keycloak, experience: 80  }, 
            { name: "More", icon: "fas fa-copyright fa-3x", svg: more, experience: 100  },            
          ]}
        />
      </div>

      <div className="w-full h-full">
        <SkillSection
          title="🔨 Tools"
          skills={[
            // 在此处新增或删除技能项，每个技能项用逗号进行分隔
            // 图标来源：https://fontawesome.com/icons
            { name: "Kubernetes", icon: "fab fa-python fa-3x", svg: k8s, experience: 80 },
            { name: "Docker", icon: "fab fa-docker fa-3x", experience: 90  },
            { name: "AWS", icon: "fab fa-aws fa-3x", experience: 90  },
            { name: "Azure", icon: "fab fa-aws fa-3x", svg: azure, experience: 80  },
            { name: "Google Cloud", icon: "fab fa-aws fa-3x", svg: googlecloud, experience: 50  },
            { name: "NodeJs", icon: "fab fa-aws fa-3x", svg: nodejs, experience: 80  },
            { name: "Tomcat", icon: "fab fa-aws fa-3x", svg: tomcat, experience: 80  },
            { name: "Apache", icon: "fab fa-aws fa-3x", svg: apache, experience: 80  },
            { name: "MySQL", icon: "fas fa-file-csv fa-3x", svg: mysql, experience: 90  },
            { name: "PostgreSQL", icon: "fas fa-file-csv fa-3x", svg: postgresql, experience: 90  },
            { name: "Mongo", icon: "fas fa-sitemap fa-3x", svg: mongo, experience: 70  },
            { name: "ElasticSearch", icon: "fas fa-sitemap fa-3x", svg: es, experience: 80  }, 
            { name: "Redis", icon: "fas fa-sitemap fa-3x", svg: redis, experience: 80  },
            { name: "Kafka", icon: "fas fa-sitemap fa-3x", svg: kafka, experience: 80  },
            { name: "VSCode", icon: "fas fa-sitemap fa-3x", svg: vscode, experience: 80  },
            { name: "IntelliJ IDEA", icon: "fas fa-sitemap fa-3x", svg: idea, experience: 80  }, 
            { name: "Git", icon: "fas fa-terminal fa-3x", svg: git, experience: 90  },
            { name: "Shell", icon: "fas fa-terminal fa-3x", experience: 90  },
            { name: "Jenkins", icon: "fas fa-copyright fa-3x", svg: jenkins, experience: 80  }, 
            { name: "Maven", icon: "fas fa-copyright fa-3x", svg: maven, experience: 80  }, 
            { name: "More", icon: "fas fa-copyright fa-3x", svg: more, experience: 100  },         
          ]}
        />
      </div>
    </div>
  );
}
