import React from "react";
import { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import programPng from "../programer.png";


export default function ProjectCard({project}) {
  const menuItems = ["Home", "About Me", "Resume", "Contact Me"];

  const [navbarOpen, setNavbarOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("Home");

  return (
    <section className="mt-2 w-90 h-100 p-4 m-auto bg-gray-200 pb-4 mb-4 border-b-2 break-inside-avoid shadow-lg">
      <header className="border-b border-gray-300 mb-2">
        <h3 className="text-lg font-semibold text-black leading-snugish">
            {project.title}
        </h3>
        <p className="leading-normal font-thin text-xs text-gray-550">
        {project.from}～{project.to}
        </p>
        <p  className="leading-normal text-sm text-gray-550 italic ">
        {project.role}
        </p>
      </header>
      {/* <div className="max-h-80 overflow-auto"> */}
      <p className="pb-3 mt-2.1 text-md text-gray-700 leading-normal border-b border-gray-300 mb-2 max-h-60 overflow-auto">
        {/* {project.description} */}
        {project.description.split('\n').map(function(item) {
          return (
            <span>
              {item}
              <br/>
            </span>
          )
        })}
      </p>
      <div className="flex flex-wrap gap-2 pt-2 max-h-20 overflow-auto ">
      { project.tags.map( tag => (
        <span class="rounded-full text-sm font-medium bg-orange-600 mx-1 my-1 px-1 py-1 text-black align-middle">{tag}</span>
      )) 
      }
      
      </div>
      {/* </div> */}
    </section>
  );
}
