import React from "react";
import { useState, useEffect } from "react";
import ProjectCard from "./ProjectCard";
import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Projects({ sectionName, Skills }) {
  //   const menuItems = ["Home", "About Me", "Resume", "Contact Me"];
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("Home");

  const title = "🚀 Projects";

  const projects = [
    {
      title: "xxx Satellite Space Entertainment",
      from: "2022.03",
      to: "now",
      role: "Team Leader & Full Stack engineer",
      description:
        "An agile web project. From customer needs to products. It make it possible to take photos or videos through satellite in space. It include reservation, payment, download, management etc. \n " +
        "As a Team leader, I made schedule, arranged tasks, pushed team proceed to fulfill goals. \n " +
        "As a full stack engineer, I programed not only back end but also front end, reviewed source code from fellow members. \n" + 
        "And also sometimes I made some POCs to solve the uncertain problem. \n",
      tags: [
        "Python",
        "Flask",
        "React",
        "Typescript",
        "MUI",
        "Casbin",
        "AWS EC2",
        "AWS S3",
        "ASW ECS/ECR",
        "AWS Lambda",
        "AWS Api Gateway",
        "AWS Event Bridge",
        "AWS Parameter store",
        "AWS Cognito",
        "AWS Elasticsearch",
        "AWS ...",
      ],
    },
    {
      title: "xxx BI",
      from: "2021.03",
      to: "2022.02",
      role: "Team Leader & SE",
      description:
        " An ETL project, using Microsoft Azure product, such as Synapse, SQL Database, JP1, etc.  \n " +
        "As a Team leader, I made schedule, arranged tasks, pushed team proceed to fulfill goals. \n " +
        "As a senior engineer, I programmed, reviewed source code of fellow members. \n" + 
        "As a senior engineer, I programmed, reviewed source code from fellow members. \n " + 
        "As a senior engineer, I helped my fellow member to solve problems, such as tuning, bug analysis \n ",
      tags: [
        "T-SQL",
        "Azure Blob storage",
        "Azure Synapse",
        "Azure SQL Database",
        "JP1",
        "Power shell",
      ],
    },
    {
      title: "xxx IOT(2)",
      from: "2020.09",
      to: "2021.02",
      role: "Team Leader & Architecture & SE  ",
      description:
        " A Web project from scratch. \n " +
        "As an Architecture, I construct this system, including Web and Batch. and speed up develop environment construction using docker. \n " +
        "As a Team leader, I made schedule, arranged tasks, pushed team proceed to fulfill goals. \n " +
        "As a SE engineer, I programmed, reviewed source code from fellow members. \n" + 
        "As a SE engineer, I released this system onto bare-metal servers in customer's Data center. \n",
      tags: [
        "JAVA",
        "Spring Boot",
        "Spring Security",
        "Thymeleaf",
        "Spring Batch",
        "PostgreSQL",
        "Tomcat",
        "Apache",
        "Shell",
        "Docker",
        "SNMP",
        "WireShark",
      ],
    }, 
    {
      title: "xxx EC(2)",
      from: "2020.08",
      to: "2019.06",
      role: "Team Leader & Technical Support Team core member ",
      description:
        " An EC project, built with Micro services. \n " +
        "As a core member of Technical Support Team, I solved the problems collected from SE teams, such as redis performance tuning, Spring Boot upgrade manual, common function development, technical proposal, source review, etc. \n " +
        "As a Team leader, I mentor my fellow member to finish their job smoothly. ",
      tags: [
        "JAVA",
        "Spring Boot",
        "Spring Batch",
        "AWS RDS",
        "AWS Lambda",
        "AWS ECS/ECR",
        "AWS Elastic Cache",
        "Kafka",
        "Shell",
        "Docker",
      ],
    },
    {
      title: "xxx IOT(1)",
      from: "2017.11",
      to: "2019.05",
      role: "Team Leader & Technical Support Team core member & SE ",
      description:
        " An IOT project, built on JAVA Spring stack. \n " +
        "As a core member of Technical Support Team, I developed common functions, wrote develop manual, fixed issues about performance tuning,  source review, etc. \n " +
        "As a Team leader, I mentor my fellow member to finish their job smoothly. ",
      tags: [
        "JAVA",
        "Spring Boot",
        "Spring RestTemplate",
        "POI",
        "Azure Blob storage",        
        "Shell",
        "Docker",
      ],
    },
    {
      title: "xxx Bank OCR",
      from: "2017.5",
      to: "2017.10",
      role: "Team Leader & Architecture & SE  ",
      description:
        " Help bank to open account through web by scanning My ID card. It used SpringBoot, PostgreSQL, Angular, VM(VirtualBox). \n " +
        " I designed and built it from scratch.  \n " +
        " I also developed all backend source, including integrate third party services. \n " + 
        " As a Team leader, I built the schedule, fixed issues during proceeding. \n " + 
        " Finally I attended Tokyo FINTECH EXPO 2017 to show our product to potential customers.  \n ",
      tags: [
        "Spring Boot",
        "Angular",       
        "VirtualBox",
        "PostGreSQL",
      ],
    },
    {
      title: "xxx EC(1)",
      from: "2013.1",
      to: "2017.4",
      role: "Team Leader & SE  ",
      description:
        " Help customer to develop&maintain his Payment Gateway Service \n " +
        " Add new feature to service, such as security etc.  \n " +
        " Fix bugs or issues found during monitoring . \n " + 
        " When offshore, I arranged tasks due to priority, reviewed the source code, fulfill the tasks. \n " + 
        " When onshore at customer's spot, I worked with them, like monitoring system, fixed bugs. \n ",
      tags: [
        "SpringMVC",
        "MySQL",       
        "Apache",
        "Redmine",
        "Kibana",
        "ElasticSearch",
        "Python",
        "Shell",
      ],
    },
    {
      title: "More...",
      from: "2003.7",
      to: "2012.12",
      role: "PG & SE& TL& PM",
      description:
        " Projects 10 years before are omitted \n ",
      tags: [
        "Omitted",
      ],
    }
  ];


  return (
    <section className="container mx-auto " id="Projects">
      <h2 className="text-5xl font-bold text-center mb-8">{title}</h2>
      <Swiper
        // slidesPerView={4}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
          // reverseDirection: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          "@0.75": {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          "@1.00": {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          "@1.50": {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
      >
        {projects.map((project) => (
          <SwiperSlide>
            <ProjectCard project={project} />
          </SwiperSlide>
        ))}

        {/* <SwiperSlide><ProjectCard /></SwiperSlide>
        <SwiperSlide><ProjectCard /></SwiperSlide>
        <SwiperSlide><ProjectCard /></SwiperSlide>
        <SwiperSlide><ProjectCard /></SwiperSlide>
        <SwiperSlide><ProjectCard /></SwiperSlide>
        <SwiperSlide><ProjectCard /></SwiperSlide>
        <SwiperSlide><ProjectCard /></SwiperSlide>
        <SwiperSlide><ProjectCard /></SwiperSlide> */}
      </Swiper>
    </section>
  );
}
