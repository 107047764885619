import React from "react";
import { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import china from "../china.png";
import japan from "../japan.png";
import usa from "../usa.png";
import Tooltips from "./Tooltips";
import training from "../training.png";
import lantingjixu from "../img/lantingjixu.jpg"

export default function ForeignLanguageCard({ service, index }) {
  const menuItems = ["Home", "About Me", "Resume", "Contact Me"];

  const [navbarOpen, setNavbarOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("Home");

  return (
    <div className="grid lg:gap-8 lg:grid-cols-3 lg:items-center justify-between">
      <div
        className={
          "lg:col-span-2" + ((index % 2 === 0) ? " " : " lg:col-start-2 ")
        }
      >
        <h3 className="text-2xl font-bold tracking-tight sm:text-3xl text-gray-900">
          {service.title}
        </h3>
        <p className="mt-3 text-lg text-gray-600">{service.subTitle}</p>
        <div className="mt-12 space-y-12">
          {service.introduces.map((introduce) => {
            return (
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 rounded-md bg-lime-600 text-gray-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-7 h-7"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium leading-6 text-gray-900">
                    {introduce.title}
                  </h4>
                  <p className="mt-2 text-gray-600">{introduce.subTitle}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        aria-hidden="true"
        className={
          "mt-10 lg:mt-0 flex w-30 h-96" + ((index % 2) === 0
            ? " flex-row-reverse "
            : " flex-row lg:col-start-1 lg:row-start-1 ")
        }
      >
        <img
          src={service.featureImage}
          alt=""
          className="rounded-lg shadow-lg bg-white object-contain"
        />
      </div>
    </div>
  );
}
