import React from "react";
import { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import china from "../china.png";
import japan from "../japan.png";
import usa from "../usa.png";
import Tooltips from "./Tooltips";
import { motion } from "framer-motion";

export default function ForeignLanguageCard({ name, read, write, speak }) {
  const menuItems = ["Home", "About Me", "Resume", "Contact Me"];

  const [navbarOpen, setNavbarOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("Home");

  return (
    <motion.div whileHover={{ scale: 1.2 }}>
      <div className="mt-2 w-40 h-32 shadow-lg">
        <img
          class="object-cover object-left w-20 h-20 md:rounded-none rounded-full mx-auto"
          src={name === "china" ? china : name === "japan" ? japan : usa}
          alt=""
          width="384"
          height="512"
        />
        <div>
          <Tooltips tips="read">
            <div className="w-full bg-gray-200 h-2 mb-2">
              <div
                className="bg-green-500 h-2"
                style={{ width: `${read}%` }}
              ></div>
            </div>
          </Tooltips>
          <Tooltips tips="write">
            <div className="w-full bg-gray-200 h-2 mb-2">
              <div
                className="bg-blue-400 h-2"
                style={{ width: `${write}%` }}
              ></div>
            </div>
          </Tooltips>
          <Tooltips tips="speak">
            <div className="w-full bg-gray-200 h-2 mb-2">
              <div
                className="bg-yellow-500 h-2"
                style={{ width: `${speak}%` }}
              ></div>
            </div>
          </Tooltips>
        </div>
      </div>
    </motion.div>
  );
}
