import React from "react";
import { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import programPng from "../programer.png";
import { motion } from "framer-motion";

const AboutMeDigitPanel = () => {
  return (
    <div className="container grid grid-cols-1 gap-6 m-4 mx-auto md:m-0 md:grid-cols-2 xl:grid-cols-3">
      <div className="flex overflow-hidden rounded-lg bg-gray-50 text-gray-800">
        <div className="flex items-center justify-center px-4 bg-lime-600 text-gray-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
            />
          </svg>
        </div>
        <div className="flex items-center justify-between flex-1 p-3">
          <p className="text-2xl font-semibold">20+</p>
          <p>Years</p>
        </div>
      </div>

      <div className="flex overflow-hidden rounded-lg bg-gray-50 text-gray-800">
        <div className="flex items-center justify-center px-4 bg-lime-600 text-gray-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path d="M487.938,162.108l-224-128a16,16,0,0,0-15.876,0l-224,128a16,16,0,0,0,.382,28l224,120a16,16,0,0,0,15.112,0l224-120a16,16,0,0,0,.382-28ZM256,277.849,65.039,175.548,256,66.428l190.961,109.12Z"></path>
            <path d="M263.711,394.02,480,275.061V238.539L256,361.74,32,238.539v36.522L248.289,394.02a16.005,16.005,0,0,0,15.422,0Z"></path>
            <path d="M32,362.667,248.471,478.118a16,16,0,0,0,15.058,0L480,362.667V326.4L256,445.867,32,326.4Z"></path>
          </svg>
        </div>
        <div className="flex items-center justify-between flex-1 p-3">
          <p className="text-2xl font-semibold">30+</p>
          <p>Projects</p>
        </div>
      </div>
      <div className="flex overflow-hidden rounded-lg bg-gray-50 text-gray-800">
        <div className="flex items-center justify-center px-4 bg-lime-600 text-gray-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="currentColor"
            className="w-6 h-6"
          >
            <polygon points="328.375 384 332.073 458.999 256.211 406.28 179.924 459.049 183.625 384 151.586 384 146.064 496 182.756 496 256.169 445.22 329.242 496 365.936 496 360.414 384 328.375 384"></polygon>
            <path d="M415.409,154.914l-2.194-48.054L372.7,80.933,346.768,40.414l-48.055-2.2L256,16.093,213.287,38.219l-48.055,2.2L139.3,80.933,98.785,106.86l-2.194,48.054L74.464,197.628l22.127,42.715,2.2,48.053L139.3,314.323l25.928,40.52,48.055,2.195L256,379.164l42.713-22.126,48.055-2.195,25.928-40.52L413.214,288.4l2.195-48.053,22.127-42.715Zm-31.646,76.949L382,270.377l-32.475,20.78-20.78,32.475-38.515,1.76L256,343.125l-34.234-17.733-38.515-1.76-20.78-32.475L130,270.377l-1.759-38.514L110.5,197.628,128.237,163.4,130,124.88,162.471,104.1l20.78-32.474,38.515-1.76L256,52.132l34.234,17.733,38.515,1.76,20.78,32.474L382,124.88l1.759,38.515L401.5,197.628Z"></path>
          </svg>
        </div>
        <div className="flex items-center justify-between flex-1 p-3">
          <p className="text-2xl font-semibold">7+</p>
          <p>Awards/Certificates</p>
        </div>
      </div>
      <div className="flex overflow-hidden rounded-lg bg-gray-50 text-gray-800">
        <div className="flex items-center justify-center px-4 bg-lime-600 text-gray-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 00-8.862 12.872M12.75 3.031a9 9 0 016.69 14.036m0 0l-.177-.529A2.25 2.25 0 0017.128 15H16.5l-.324-.324a1.453 1.453 0 00-2.328.377l-.036.073a1.586 1.586 0 01-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 01-5.276 3.67m0 0a9 9 0 01-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
            />
          </svg>
        </div>
        <div className="flex items-center justify-between flex-1 p-3">
          <p className="text-2xl font-semibold">3</p>
          <p>Natural Languages</p>
        </div>
      </div>
      <div className="flex overflow-hidden rounded-lg bg-gray-50 text-gray-800">
        <div className="flex items-center justify-center px-4 bg-lime-600 text-gray-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
            />
          </svg>
        </div>
        <div className="flex items-center justify-between flex-1 p-3">
          <p className="text-2xl font-semibold">10+</p>
          <p>Program Languages</p>
        </div>
      </div>
      <div className="flex overflow-hidden rounded-lg bg-gray-50 text-gray-800">
        <div className="flex items-center justify-center px-4 bg-lime-600 text-gray-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"
            />
          </svg>
        </div>
        <div className="flex items-center justify-between flex-1 p-3">
          <p className="text-2xl font-semibold">20+</p>
          <p>Tools/Frameworks</p>
        </div>
      </div>
    </div>
  );
};

export default function About() {
  const menuItems = ["Home", "About Me", "Resume", "Contact Me"];

  const [navbarOpen, setNavbarOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("Home");

  const index = 0;

  return (
    <section className="z-0" id="About">
      <div className="bg-stone-500">
        <div className="container flex flex-col items-center px-4 py-6 mx-auto text-center md:py-10 md:px-10 lg:px-32 text-gray-900 ">
          <motion.div
            className="hidden md:block md:relative"
            initial="hidden"
            animate="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 1.0, duration: 1.0 }}
            variants={{
              hidden: { opacity: 0, x: 0 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <section className="hidden md:block md:relative md:-mt-[40%] p-4 my-6 md:p-8 bg-gray-100 text-gray-800">
              <AboutMeDigitPanel />
            </section>
          </motion.div>

          <div className="lg:-mt-20"> 
            <h1 className=" text-5xl font-bold leading-none xl:max-w-3xl text-gray-50 pt-[5%] lg:pt-[20%]">
              About Me
            </h1>

            <section className="md:hidden md:relative p-4 my-6 md:p-8 bg-gray-100 text-gray-800">
              <AboutMeDigitPanel />
            </section>

            <p className="mt-4 mb-4 text-lg sm:mb-6 xl:max-w-3xl text-gray-50 text-2xl font-medium tracking-tight sm:text-3xl text-white leading-10">
              Dedicated to IT area for over{" "}
              <span className="font-bold ">20</span> years.
              <br />
              Experienced <span className="font-bold ">30+</span> projects.
              <br />
              Master <span className="font-bold ">10+</span> program languages.
              <br />
              Develop with <span className="font-bold ">10+</span> frameworks.
              <br />
              Use <span className="font-bold ">20+</span> tools.
              <br />
              Speak chinese, english and japanese.
              <br />
              Your reliable!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
