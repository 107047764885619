import React from "react";
import { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import programPng from "../programer.png";
import { motion } from "framer-motion";

export default function Hero() {
  const menuItems = ["Home", "About Me", "Resume", "Contact Me"];

  const [navbarOpen, setNavbarOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("Home");

  const index = 0;

  return (
    <section className="bg-gray-100 text-gray-800 mt-10 bg-gradient-to-b from-white to-lime-600 bg-lime-600 h-screen">
      <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl ">
        <div className="flex-col-reverse grid lg:gap-8 lg:grid-cols-3 justify-center lg:items-center lg:justify-between">
          <div className={"lg:col-span-2 "}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.5 }}
              transition={{ duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
              }}
            >
              <h1 className="text-4xl font-bold leading-none sm:text-5xl">
                Hi There！🤝
                <br />
                Nice meeting you!
              </h1>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.5 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
              }}
            >
              <p className="mt-8 mb-6 text-2xl">
                I am <span className="text-lime-600">Kevin.Han</span>
                <br />What I am professional at are :
              </p>
            </motion.div>


            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.5 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
              }}
            >           
            <p className="mb-12 text-2xl">
              <Typewriter
                options={{
                  strings: [
                    "IT Consulting🗃️",
                    "System Architecture🏛️",
                    '<span class="text-blue-600">Full Stack development💻</span>',
                    "IT Education/Training🚀",
                    "Code Quantity Audit✨",
                    "Project Management🥅",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </p>
            </motion.div> 
          </div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: 50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div
              aria-hidden="true"
              className={"mt-2 md:mt-10 lg:mt-0 flex justify-center"}
            >
              <img
                src={programPng}
                alt=""
                className="w-2/3 h-2/3 lg:w-full lg:h-full md:rounded-none rounded-full rounded-lg"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
