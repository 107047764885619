import React from "react";
import { useState, useEffect } from "react";
// import go from "../go.svg";
// import mysql from "../mysql.svg";
// import postgresql from "../postgresql.svg";
// import mybatis from "../mybatis.svg";
// import keycloak from "../keycloak.svg";
import { motion } from "framer-motion";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.3
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

export default function SkillSection({ title, skills }) {
  //   const menuItems = ["Home", "About Me", "Resume", "Contact Me"];

  const [navbarOpen, setNavbarOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("Home");

  return (
    <section className="bg-white-100 text-gray-800">
      <div className="container p-6 mx-auto space-y-6 text-center lg:p-8 lg:space-y-8">
        <h2 className="text-5xl font-bold">{title}</h2>

        <motion.div variants={container} initial="hidden" whileInView="visible">
          <div className="flex flex-wrap justify-center justify-between">
            {skills &&
              skills.map((skill, index) => {
                return (
                  <motion.div
                    whileInView="visible"
                    variants={item}
                    whileHover={{ scale: 1.2 }}
                  >
                    <div
                      key={index}
                      className="mx-2 mb-2 w-28 sm:mx-0 sm:my-4 bg-background-light rounded-lg shadow-md hover:bg-gray-50"
                    >
                      {!!skill.svg && (
                        <i
                          className={" text-gray-500 flex justify-center mt-1"}
                        >
                          {/* {skill.svg} */}
                          <div className="w-12 h-11">
                            <img
                              className="object-scale-down w-full h-full"
                              src={skill.svg}
                              alt="mySvgImage"
                              style={{ filter: "grayscale(100%)" }}
                            />
                          </div>
                        </i>
                      )}

                      {!skill.svg && (
                        <i className={skill.icon + " text-gray-500"}></i>
                      )}

                      {/* <i className={skill.icon + " text-gray-500"}></i> */}
                      <h3 className="text-gray-600 pt-0">{skill.name}</h3>
                      <div className="w-full bg-gray-200 h-1 mb-1">
                        <div
                          className="bg-green-500 h-1"
                          style={{ width: `${skill.experience}%` }}
                        ></div>
                      </div>
                    </div>
                  </motion.div>
                );
              })}
          </div>
        </motion.div>
      </div>
    </section>
  );
}
